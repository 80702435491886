import Clipboard from 'clipboard';
import eqcol from 'eqcol';
import WebFont from 'webfontloader';
import debounce from 'lodash.debounce';
import imagesloaded from 'imagesloaded';

let runOnce = false;

WebFont.load({
	custom: {
		families: ['Black Diamond']
	}
});

function hasClass(el, className) {
	return el.classList ? el.classList.contains(className) :
		new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
}

function addClass(el, className) {
	if (el.classList) {
		el.classList.add(className);

	} else {
		el.className += ' ' + className;
	}
}

function removeClass(el, className) {
	if (el.classList) {
		el.classList.remove(className);

	} else {
		el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
	}
}

function loadLogos(rows) {
	rows.forEach(row => {
		const logos = row.querySelectorAll('.project-logo');

		if (logos.length !== 0) {
			row.addEventListener('equaled', () => {
				logos.forEach(logo => {
					addClass(logo, 'fade');
				});
			});
		}
	});
}

(function () {
	const clipboard = new Clipboard('.clipboard');
	let timeout;
	let timeout2;

	clipboard.on('success', e => {
		const bubble = document.createElement('div');

		bubble.setAttribute('class', 'bubble-wrapper fade');
		bubble.innerHTML = '<span class="bubble">Copied</span>';
		e.trigger.parentNode.appendChild(bubble);

		setTimeout(() => {
			bubble.parentNode.removeChild(bubble);
		}, 1000);
	});

	if (window.matchMedia('(max-width: 543px)').matches) {
		clipboard.destroy();
	}

	// Wait for images to load before adjusting column height
	window.onresize = debounce(() => {
		const elements = document.querySelectorAll('[data-eqcol]');

		imagesloaded(elements, () => {
			const rows = eqcol(elements);

			if (!runOnce) {
				loadLogos(rows);
				runOnce = true;
			}
		});
	}, 200);

	window.dispatchEvent(new Event('resize'));

	const logoEnter = e => {
		const $this = e.currentTarget;
		const $next = e.currentTarget.nextElementSibling;

		if (timeout && hasClass($this, 'active')) {
			clearTimeout(timeout);
		}

		addClass($this, 'active');

		timeout2 = setTimeout(() => {
			if ($next) {
				addClass($next, 'active');
			}

		}, 500);
	};

	const logoLeave = e => {
		const $next = e.currentTarget.nextElementSibling;

		if (timeout2) {
			clearTimeout(timeout2);
		}

		if ($next) {
			const event = document.createEvent('HTMLEvents');
			event.initEvent('mouseleave', true, false);
			$next.dispatchEvent(event);
		}
	};

	const detailEnter = e => {
		const $prev = e.currentTarget.previousElementSibling;

		if (timeout && $prev && hasClass($prev, 'active')) {
			clearTimeout(timeout);
		}
	};

	const detailLeave = e => {
		const $this = e.currentTarget;
		const $prev = e.currentTarget.previousElementSibling;

		timeout = setTimeout(() => {
			removeClass($this, 'active');

			if ($prev) {
				removeClass($prev, 'active');
			}
		}, 200);
	};

	for (const label of document.querySelectorAll('nav label')) {
		label.addEventListener('click', e => {
			const action = e.currentTarget.getAttribute('data-action');

			if (action) {
				if (action === 'open') {
					addClass(document.body, 'no-scroll');

				} else {
					removeClass(document.body, 'no-scroll');
				}
			}
		}, false);
	}

	for (const item of document.querySelectorAll('.project-logo-hover')) {
		item.addEventListener('mouseenter', logoEnter, false);
		item.addEventListener('mouseleave', logoLeave, false);
	}

	for (const item of document.querySelectorAll('.project-hover')) {
		item.addEventListener('mouseenter', detailEnter, false);
		item.addEventListener('mouseleave', detailLeave, false);
	}
})();
